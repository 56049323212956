import React from "react";
import ReactDOM from 'react-dom/client';
import { persistStore } from 'redux-persist'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import configureAppStore from "./shared/configs/store.config";
import { AppRoutes } from "./routing/AppRoutes";

const store = configureAppStore().store
const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()

Sentry.init({
  dsn: "https://a4541423b10747c5872f7d8eab3d32a1@o1268124.ingest.sentry.io/6455103",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
        <PersistGate loading={`Loading`} persistor={persistor}>
          <AppRoutes />
        </PersistGate>
    </QueryClientProvider>
  </Provider>
);
