import { combineReducers } from '@reduxjs/toolkit';
import AuthSlice from './auth.reducer';
import LeaderBoardSlice from "./leader-board.reducer";
import TournamentSlice from "./tournament.reducer";

const rootReducer = combineReducers({
  auth: AuthSlice.reducer,
  leaderBoard: LeaderBoardSlice.reducer,
  tournament: TournamentSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;