import { all, call, put, takeLatest } from 'redux-saga/effects'
import AuthSlice from "../reducers/auth.reducer";
import FirebaseService from "../services/firebase.service";
import { UserCredential } from "firebase/auth";
import AccountService from '../services/account.service';

const {
  signInWithRedirect,
  signInWithRedirectFailed,
  signInWithRedirectSuccess,
  authInit,
  authInitSuccess,
  authInitFailed,
  signOut,
  signOutSuccess,
  signOutFailed,
  getUserAccount,
  getUserAccountSuccess,
  getUserAccountFailed,
  registerUser,
  registerUserSuccess,
  registerUserFailed
} = AuthSlice.actions

function* signInWithRedirectSaga(action: { payload: any }) {
  try {
    yield call(FirebaseService.signInWithRedirect, action.payload)
    // @ts-ignore
    // const result = yield call<any>(FirebaseService.getRedirectResult, action.payload)
    yield put(signInWithRedirectSuccess())
  } catch (error: any) {
    yield put(signInWithRedirectFailed(error))
  }
}

function* authInitSaga() {
  try {
    // @ts-ignore
    yield call(FirebaseService.onAuthStateChanged)

    // @ts-ignore
    const { user } = yield call(FirebaseService.getRedirectResult)

    yield put(authInitSuccess(user))

  } catch (error: any) {
    yield put(authInitFailed(error))
  }
}

function* signOutSaga() {
  try {
    yield call(FirebaseService.signOut)
    yield put(signOutSuccess())
  } catch (error: any) {
    yield put(signOutFailed(error))
  }
}

function* registerUserSaga(action: any) {
  try {
    yield call(AccountService.registerUser, action.payload)
    yield put(registerUserSuccess())
  } catch (error: any) {
    yield put(registerUserFailed(error))
  }
}

function* getUserAccountSaga(action: any) {
  try {
    // @ts-ignore
    const userAccount = yield call(AccountService.getUserAccount, action.payload)
    yield put(getUserAccountSuccess(userAccount))
  } catch (error: any) {
    yield put(getUserAccountFailed(error))
  }
}

export default function* root() {
  yield all([
    takeLatest(signInWithRedirect, signInWithRedirectSaga),
    takeLatest(authInit, authInitSaga),
    takeLatest(signOut, signOutSaga),
    takeLatest(registerUser, registerUserSaga),
    takeLatest(getUserAccount, getUserAccountSaga),
  ])
}