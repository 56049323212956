import firebase from '../services/firebase.service'
const { setData, get, ref, database } = firebase
const AccountService = {
  registerUser: ({ user }: any) => setData({ table: `accounts/${user.uid}`, data: user }),
  getUserAccount: async ({ uid }: any) => {
    const userAccountRef = await get(ref(database, `accounts/${uid}`))
    return userAccountRef.val();
  }
}

export default AccountService