import {createSlice, Draft, PayloadAction, SliceCaseReducers} from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";
import {IStatus} from "../models/status-model";

interface StateInterface {
  leaderBoardList: [],
  status: IStatus
}

const initialState: StateInterface = {
  leaderBoardList: [],
  status: {
    ...resetActionStatus
  }
}
const LeaderBoardSlice = createSlice({
  name: 'LeaderBoard',
  initialState,
  reducers: {
    getLeaderBoard: (state: any) => {
      return {
        ...state,
        status: {
          ...initActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
    getLeaderBoardSuccess: (state: any, {payload}) => {
      return {
        ...state,
        leaderBoardList: payload,
        status: {
          ...successActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
    getLeaderBoardFailed: (state: any, {payload}) => {
      return {
        ...state,
        status: {
          ...failedActionStatus,
          type: 'getLeaderBoard'
        }
      }
    },
  }
})

export default LeaderBoardSlice
