import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import MasterLayout from '../layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
// import Dashboard from '../pages/Dashboard'
// import {MenuTestPage} from '../pages/MenuTestPage'
// import {getCSSVariableValue} from '../assets/ts/_utils'
// import {BattleStreams} from "../modules/battle-streams";
// @ts-ignore
const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const HomePage = lazy(() => import('../modules/home/HomePage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  // @ts-ignore
  return (
    <Routes>
      <Route element={<MasterLayout/>}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home'/>}/>
        {/* Pages */}
        <Route path='dashboard' element={<HomePage/>}/>
        {/*<Route path='/battle-streams/*' element={*/}
        {/*  <SuspensedView>*/}
        {/*    <BattleStreams/>*/}
        {/*  </SuspensedView>*/}
        {/*}/>*/}
        <Route path='*' element={<Navigate to='/error/404'/>}/>
      </Route>
    </Routes>
  )
}

const SuspensedView = ({children}: any) => {
  // const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      // '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
