import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, get, child, update } from "firebase/database";
import {
  getAuth,
  signInWithRedirect,
  onAuthStateChanged,
  updateProfile,
  getRedirectResult,
  UserCredential,
  FacebookAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDaaoKjidXN7QzLFhjBhzoOzX--HkD9c98",
  authDomain: "lodiverse-staging.firebaseapp.com",
  databaseURL: "https://lodiverse-staging-default-rtdb.firebaseio.com",
  projectId: "lodiverse-staging",
  storageBucket: "lodiverse-staging.appspot.com",
  messagingSenderId: "853107098278",
  appId: "1:853107098278:web:30a0ba5a29b28cf0b3aa67",
  measurementId: "G-MND8PEZ3CL"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const authProvider: any = {
  facebook: new FacebookAuthProvider(),
  google: new GoogleAuthProvider()
}
const database = getDatabase();

const FirebaseService = {
  app,
  get,
  ref,
  auth,
  update,
  database,
  analytics,
  currentUser: auth.currentUser,
  onAuthStateChanged: () => onAuthStateChanged(auth, () => { }),
  updateProfile: ({ payload, currentUser }: any) => updateProfile(currentUser, payload),
  signInWithRedirect: ({ provider }: { provider: string }) => signInWithRedirect(auth, authProvider[provider]),
  getRedirectResult: () => getRedirectResult(auth),
  getData: ({ table }: any) => get(child(ref(database), table)),
  setData: ({ table, data }: any) => set(ref(database, table), data),
  updateData: ({ table, data }: any) => update(ref(database, table), data),
  signOut: () => auth.signOut(),
}


export default FirebaseService
