/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {useSelector} from "react-redux";
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import ErrorsPage from '../modules/errors/ErrorsPage'
import AuthPage from "../modules/auth/AuthPage";
import App from '../app'
import {RootState} from "../shared/reducers/root.reducer";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes = () => {
  const {currentUser} = useSelector((state: RootState) => state.auth)
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App/>}>
          <Route path='error/*' element={<ErrorsPage/>}/>
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes/>}/>
              <Route index element={<Navigate to='/dashboard'/>}/>
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage/>}/>
              <Route path='*' element={<Navigate to='/auth'/>}/>
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
