import { createSlice } from '@reduxjs/toolkit'
import {
  failedActionStatus,
  initActionStatus,
  resetActionStatus,
  successActionStatus
} from "../constants/status.constant";
import {IStatus} from "../models/status-model";
import { GoogleAuthProvider } from "firebase/auth";

interface InitialState {
  currentUser: any,
  userAccount: any,
  status: IStatus
}

const initialState: InitialState = {
  currentUser: null,
  userAccount: null,
  status: {
    ...resetActionStatus
  }
}

const AuthSlice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    signInWithRedirect: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'signInWithRedirect',
          ...initActionStatus
        }
      }
    },
    signInWithRedirectSuccess: (state, { payload }) => {
      const credential: any = GoogleAuthProvider.credentialFromResult(payload);
      const token = credential.accessToken;

      const user = payload.user;

      return {
        ...state,
        user,
        token,
        status: {
          type: 'signInWithRedirect',
          ...successActionStatus
        }
      }
    },
    signInWithRedirectFailed: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'signInWithRedirect',
          ...failedActionStatus
        }
      }
    },
    authInit: (state: any) => {
      return {
        ...state,
        status: {
          type: 'authInit',
          ...initActionStatus
        }
      }
    },
    authInitSuccess: (state: any, { payload }) => {
      if (!payload) return

      return {
        ...state,
        currentUser: payload,
        status: {
          type: 'authInit',
          ...successActionStatus
        }
      }
    },
    authInitFailed: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'authInit',
          ...failedActionStatus
        }
      }
    },
    signOut: (state) => {
      return {
        ...state,
        status: {
          type: 'signOut',
          ...initActionStatus
        }
      }
    },
    signOutSuccess: (state) => {
      return {
        ...state,
        currentUser: null,
        status: {
          type: 'signOut',
          ...successActionStatus
        }
      }
    },
    signOutFailed: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'signOut',
          ...failedActionStatus
        }
      }
    },

    getUserAccount: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'getUserAccount',
          ...initActionStatus
        }
      }
    },

    getUserAccountSuccess: (state, { payload }) => {
      return {
        ...state,
        userAccount: payload,
        status: {
          type: 'getUserAccount',
          ...successActionStatus
        }
      }
    },
    getUserAccountFailed: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'getUserAccount',
          ...failedActionStatus
        }
      }
    },


    registerUser: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...initActionStatus
        }
      }
    },
    registerUserSuccess: (state) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...successActionStatus
        }
      }
    },
    registerUserFailed: (state, { payload }) => {
      return {
        ...state,
        status: {
          type: 'registerUser',
          ...failedActionStatus
        }
      }
    },

  }
})

export default AuthSlice
