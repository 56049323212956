import {applyMiddleware} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import {composeWithDevTools} from 'redux-devtools-extension';
import {routerMiddleware} from 'react-router-redux';
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga';
import {persistReducer} from 'redux-persist'
import {createBrowserHistory} from 'history';
import {createLogger} from 'redux-logger';
import rootReducer from '../reducers/root.reducer';
import rootSaga from '../sagas/root.saga'

const history = createBrowserHistory();
const saga = createSagaMiddleware();
const router = routerMiddleware(history);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const configureAppStore = () => {
  const logger = createLogger({
    collapsed: true,
  });
  let middlewareEnhancer: Function = () => { }
  
  if (process.env.NODE_ENV !== 'production') {
    middlewareEnhancer = applyMiddleware(saga, logger, router)
  } else {
    middlewareEnhancer = applyMiddleware(saga, router)
  }
  
  const composedEnhancers = composeWithDevTools(middlewareEnhancer)
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [],
    enhancers: [composedEnhancers]
  })
  saga.run(rootSaga);

  return {store, dispatch: store.dispatch}
}

export default configureAppStore;
