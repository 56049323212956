import {IStatus} from "../models/status-model";

export const resetActionStatus: IStatus = {
  submitted: false,
  success: false,
  loading: false,
  type: ''
}

export const initActionStatus: IStatus = {
  submitted: true,
  success: false,
  loading: true,
}

export const successActionStatus: IStatus = {
  submitted: true,
  success: true,
  loading: false,
}

export const failedActionStatus: IStatus = {
  submitted: true,
  success: false,
  loading: false,
}
