import {all, call, put, takeLatest} from 'redux-saga/effects'
import {PayloadAction} from "@reduxjs/toolkit";
import TournamentSlice from "../reducers/tournament.reducer";
import TournamentService from "../services/tournament.service";

const {
  postTournament,
  postTournamentSuccess,
  postTournamentFailed,
  getTournamentList,
  getTournamentListSuccess,
  getTournamentListFailed
} = TournamentSlice.actions

function* getTournamentListSaga() {
  try {
    const data: Promise<any> = yield call(TournamentService.getTournamentList)
    yield put(getTournamentListSuccess(data))
  }catch (e) {
    yield put(getTournamentListFailed(e))
  }
}
function* postTournamentSaga(action: PayloadAction) {
  try {
    const data: Promise<any> = yield call(TournamentService.postTournament, action.payload)
    yield put(postTournamentSuccess(data))
  }catch (e) {
    yield put(postTournamentFailed(e))
  }
}

export default function* root(){
  yield all([
    takeLatest(getTournamentList, getTournamentListSaga),
    takeLatest(postTournament, postTournamentSaga)
  ])
}