import {all, fork} from 'redux-saga/effects'
import Auth from './auth.saga';
import LeaderBoard from './leader-board.saga';
import Tournament from './tournament.saga';

export default function* root() {
  yield all([
    fork(Auth),
    fork(LeaderBoard),
    fork(Tournament),
  ])
}
