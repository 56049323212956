import React, {useEffect} from 'react';
import splash from './assets/images/eatsleepplaygames.png';
import styled from "styled-components";
import useNoCopy from "./shared/hooks/no-copy.hook";

const SplashBG = styled.div`
  margin:0 ;
  padding: 0;
  position: absolute;
  background-image: url('${splash}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  width: -webkit-fill-available;
  height: 1118px;
   -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

const SplashImage = styled.img`
    position: absolute;
    z-index: 1;
   -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

`

const Header = styled.div`
    display: flex;
    justify-content: center;
       -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`

function App() {
  // useNoCopy({})
  useEffect(()=>{
    document.addEventListener('contextmenu', event => event.preventDefault())
  },[])
  return (
    <div className="App">
      <Header>
        <SplashImage src={splash} draggable={false}/>
        <SplashBG/>
      </Header>
    </div>
  );
}

export default App;
